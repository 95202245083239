import './App.css';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { faCode } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

const firebaseConfig = {
  apiKey: "AIzaSyCvC6CpcPFZtDdcLMRNHMVUKe3hUCYP1A8",
  authDomain: "ozkokdev-5bd80.firebaseapp.com",
  projectId: "ozkokdev-5bd80",
  storageBucket: "ozkokdev-5bd80.appspot.com",
  messagingSenderId: "993584645064",
  appId: "1:993584645064:web:3141f574eeb4158e7eb6fd",
  measurementId: "G-C8GYPKCH4B"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'ok');


library.add(fab)

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p className="App-title">
          Ozkok Mobile Dev <FontAwesomeIcon icon={faCode} />
        </p>
        <div className='logos'>

          <a style={{ marginRight: 50 }}target='_blank' href="https://github.com/erdemozk/" rel="noopener noreferrer">
            <FontAwesomeIcon icon="fa-brands fa-github" size='5x' title='Github' color='#48A1B7'/>
          </a>

          <a target='_blank' href="https://www.linkedin.com/in/erdemozk" rel="noopener noreferrer">
            <FontAwesomeIcon icon="fa-brands fa-linkedin" size='5x' color='#48A1B7' />
          </a>

        </div>
      </header>

    </div>
  );
}

export default App;